<template>
  <!--<v-container fluid>
    <v-row>
      <v-col cols="12 text-center" v-show="!mostrarContenido">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="100"
          :width="7"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row v-show="mostrarContenido">
      <v-col cols="12">
        <p class="font-weight-bold">INFORMACIÓN DEL SISTEMA</p>
      </v-col>

      <v-col cols="12">
        <v-card color="#385F73" dark>
          <v-card-title class="headline"> VENTAS </v-card-title>

          <v-card-text>
            TOTAL DE VENTAS DE HOY:
            <span class="font-weight-bold" v-text="totalVentasDia"></span>
          </v-card-text>

          <v-card-actions>
            <v-btn :to="{ name: 'ventasPanel' }" as="v-btn" color="success">
              <v-icon>mdi-cart</v-icon>
              VER VENTAS
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12">
        <hr />
      </v-col>

      <v-col cols="12">
        <bar-chart :chart-data="dataGraficoBarras" :options="optionsChart"></bar-chart>
      </v-col>

      <v-col cols="12">
        <line-chart :chart-data="dataGraficoLineas" :options="optionsChart"></line-chart>
      </v-col>

      <v-col cols="12">
        <hr />
      </v-col>
    </v-row>
  </v-container>-->
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1>BIENVENIDO AL SISTEMA</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BarChart from "../Componentes/Graficos/Bar";
import LineChart from "../Componentes/Graficos/Line";
export default {
  components: {
    BarChart,
    LineChart,
  },
  data() {
    return {
      mostrarContenido: false,
      dataGraficoBarras: {},
      dataGraficoLineas: {},
      totalVentasDia: 0,
      arrDiasSemana: [],
      arrValoresVentaSemana: [],
      arrMesesAnio: [],
      arrValoresVentaMensual: [],
      optionsChart: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },

  methods: {
    getDatos() {
      this.mostrarContenido = false;
      this.axios({
        method: "POST",
        url: "api/panel/inicio",
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            let data = response.data;
            this.totalVentasDia = data.totalVentasDia;
            this.arrDiasSemana = data.arrDiasSemana;
            this.arrMesesAnio = data.arrMesesAnio;
            this.arrValoresVentaMensual = data.arrValoresVentaMensual;
            this.arrValoresVentaSemana = data.arrValoresVentaSemana;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toasted.error("Ocurrio un error", {
            icon: "mdi-close",
          });
        })
        .finally(() => {
          this.mostrarContenido = true;
          this.generarGraficos();
        });
    },

    generarGraficos() {
      this.dataGraficoBarras = {
        labels: this.arrDiasSemana,
        datasets: [
          {
            label: "VENTAS ULTIMOS 7 DIAS",
            backgroundColor: "#f87979",
            data: this.arrValoresVentaSemana,
          },
        ],
      };

      this.dataGraficoLineas = {
        labels: this.arrMesesAnio,
        datasets: [
          {
            label: "VENTAS ULTIMOS 12 MESES",
            backgroundColor: "#f87979",
            data: this.arrValoresVentaMensual,
          },
        ],
      };
    },
  },
  created() {
    this.getDatos();
  },
};
</script>

<style scoped>
.small {
  max-height: 300px;
}
</style>
